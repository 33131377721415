import * as React from "react"
import { MDXRenderer} from "gatsby-plugin-mdx"

const Card = ({ card }) => {

  return (
    <div 
        key={card.id}
        className="px-10 py-5 w-9/12 m-6 mx-auto h-3/6 border-gray-800 border-b border-r">
      <div>
        <h3>{card.childMdx.frontmatter.name}</h3>
        <div className="px-10 py-5 bg-black">
          <MDXRenderer>{card.childMdx.body}</MDXRenderer>
        </div>
      </div>
    </div>
  )
 }

export default Card
