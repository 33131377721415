import * as React from "react"

const HomeHero = () => (

        <div className="container mx-auto px-8 py-12 mb-12 bg-hero bg-left-bottom bg-no-repeat">
            <div className="h-full">
            <h1>Logickal</h1>
            
            <h3>Improvised Electronic Musick</h3>
            Nashville, TN
            </div>
        </div>
);

export default HomeHero;