import * as React from "react"

import Seo from "../components/seo"

import HomeHero from "../components/homehero"
import CardContainer from "../components/cardcontainer"

const IndexPage = () => (
  <div>
    <Seo title="Home" />
    
    <HomeHero></HomeHero>
    <CardContainer></CardContainer>
  </div>

)

export default IndexPage
