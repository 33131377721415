import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import Card from "./card"


const CardContainer = () => {
    const data = useStaticQuery(graphql`
      query MyQuery {
        allFile(
          filter: {sourceInstanceName: {eq: "cards"}}
          sort: {fields: childrenMdx___frontmatter___order}
        ) {
          nodes {
            childMdx {
              frontmatter {
                name
                order
                title
              }
              id
              body
            }
          }
        }
      }

    `)
    const cards = data.allFile.nodes;
    return (
        <div 
        className="container mx-auto box-border px-4 isolate clear-both">
            <div>
                {cards.map(cardData => (
                    <Card card={cardData}/>
                ))}
            </div>
        </div>
    )
}

export default CardContainer;